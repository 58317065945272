import { Typography } from '@material-ui/core';
import dompurify from 'dompurify';
import React from 'react';
import { Link } from 'react-router-dom';
import { profilePath } from '~/constants/routes';
import styled from '~/styles';
import { withLinks } from '~/utils/withLinks';

const MENTION_RE = /({{[0-9]+,"[^}]*"}})/gi;
export const MENTION_EXTRACT = /{{([0-9]+),"([^}]*)"}}/;

const Mention = styled(Link)`
  border-radius: 5px;
  color: ${(p) => p.theme.palette.primary.main};
  font-weight: bold;
  padding: 2px 5px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;

  span {
    margin-right: 2px;
  }
`;

const EmojiContainer = styled.span`
  line-height: 1.3;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;

  img {
    vertical-align: middle;
    height: 1.3em;
    width: 1.3em;
    margin: 0 1px;
    margin-bottom: 1px;
  }

  a {
    font-weight: 500;
    color: ${(p) => p.theme.palette.secondary.main};
  }
`;

interface Props {
  value: string;
  bgColor?: string;
}

function EnhancedContent({ value, bgColor }: Props) {
  const v = withLinks(value);
  const parts = v.split(MENTION_RE);
  return (
    <Typography color="inherit">
      {parts.map((part, i) => {
        if (i % 2 !== 0) {
          const extract = part.match(MENTION_EXTRACT);
          if (extract) {
            const [, id, fullname] = extract;
            return (
              <Mention
                key={`mention-${Math.random()}`}
                to={profilePath(id)}
                style={{ backgroundColor: bgColor || 'rgba(0, 0, 0, 0.05)' }}
              >
                <span>@</span>
                {fullname}
              </Mention>
            );
          }
        }
        const htmlWithEmoji = part as string;
        return (
          <EmojiContainer
            key={`mention-${Math.random()}`}
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(htmlWithEmoji, {
                ADD_ATTR: ['target'],
              }),
            }}
          />
        );
      })}
    </Typography>
  );
}

export default EnhancedContent;
